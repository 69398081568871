// Import header_footer js
import Navbar from './pages/header_footer/Navbar';

// Import content pages js
import Home from "./pages/content/Home";
import Login from "./pages/content/Login";
import SignUp from "./pages/content/SignUp";
import Warning from "./pages/content/Warning";
import Profile from "./pages/content/UserProfile";

// Import routing
import { Route, Routes, useLocation } from "react-router-dom"

// Import API URL
import api_url from './config.json';

function App() {
	// Initialise
	const loadingScreen = document.getElementById("loadingScreen");
	const loadingMsg = document.getElementById("loadingMsg");
	const location = useLocation();

	// Check if loading screen exist
	if (loadingScreen) {
		// If exist, check if server is online
		fetch(api_url["api_status"] + "/testapi")
			.then(response => response.json())
			.then(response => {
				if (response["Success"] === "PC is online.") {
					loadingScreen.style.marginTop = "-1000vh";
					loadingScreen.style.overflow = "hidden";
				}
			})
			.catch(error => {
				loadingMsg.innerHTML = "Server Is Offline";
			});
	}

	// Routing
	return (
		<div className='mainContainer'>
			<Navbar />
			<div className="container">
				<Routes location={location} key={location.key}>
					<Route path="/" element={<Home />} />
					<Route path="/user-login" element={<Login />} />
					<Route path="/user-profile" element={<Profile />} />
					<Route path="/signup" element={<SignUp />} />
					<Route path="*" element={<Warning />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
