import { Link, useNavigate, useLocation } from "react-router-dom";
import { ImUser } from "react-icons/im";
import { FaUser } from "react-icons/fa6";
import { GiExitDoor } from "react-icons/gi";
import { FaUserPlus } from "react-icons/fa";
import { useState, useEffect } from "react";
import { clearLocalStorage } from '../functions/LoginCheck';

export default function Navbar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [validUser, setValidUser] = useState(false);
    useEffect(() => {
        if (location.pathname !== "\\user-login") {
            if (localStorage.getItem("lh_dashboard_access_token") !== null)
                setValidUser(true);
            else
                setValidUser(false);
        }
        else
            setValidUser(false);
    }, [location])
    return (
        <nav className="nav">
            <div className="nav-container">
                <CustomLink to="/" onClick={() => {
                    navigate("/", { replace: true });
                }}>
                    <div className="site-title-initial">
                        <span>蕭</span>
                    </div>
                </CustomLink>
            </div>
            <div className="nav-container2">
                <div className="navLine" />
                {validUser && (
                    <CustomLink to="/user-profile" onClick={() => {
                        navigate("/user-profile", { replace: true });
                    }}>
                        <div className="navLinks">
                            <FaUser />
                            <span>Profile</span>
                        </div>
                    </CustomLink>
                )}
                {validUser && (
                    <CustomLink to="/signup" onClick={() => {
                        navigate("/signup", { replace: true });
                    }}>
                        <div className="navLinks">
                            <FaUserPlus />
                            <span>Invite</span>
                        </div>
                    </CustomLink>
                )}
                {validUser && (
                    <CustomLink to="/" onClick={() => {
                        clearLocalStorage();
                        navigate("/", { replace: true });
                    }}>
                        <div className="navLinks">
                            <GiExitDoor />
                            <span>Logout</span>
                        </div>
                    </CustomLink>
                )}
                {!validUser && (
                    <CustomLink to="/user-login" onClick={() => {
                        navigate("/user-login", { replace: true });
                    }}>
                        <div className="navLinks">
                            <ImUser />
                            <span>Login</span>
                        </div>
                    </CustomLink>
                )}
            </div>
        </nav>
    );
}

function CustomLink({ to, children, ...props }) {
    //const path = window.location.pathname;
    return (
        <Link className="navLink-Container" to={to} {...props}>{children}</Link>
    )
}