import { useEffect, useState } from 'react';
import { MdPermMedia } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { FaNetworkWired } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import { IoLogoGameControllerB, IoMdMore } from "react-icons/io";

import { clearLocalStorage } from '../functions/LoginCheck';

// Import loading screen
import Loadings from './Loading';

// Import API URL
import api_url from '../../config.json';
// import dashboardData from '../../dashboard_config.json';

export default function Home() {
    // Initialise
    const navigate = useNavigate();
    const [menuButton, setMenuButton] = useState([]);
    const [loadState, setLoadState] = useState(false);
    const [dashboardData, setDashboardData] = useState([]);

    useEffect(() => {
        // Scroll to the top on load
        window.scrollTo(0, 0);
        setLoadState(true);
        let token = localStorage.getItem('lh_dashboard_access_token');
        let content_url = api_url["api_dashboard"]
        if (token === null || token === "")
            content_url += "/items/unauthorised/content"
        else
            content_url += "/items/authorised/content"
        fetch(content_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response["detail"] === "Could not validate credentials") {
                    navigate("/", { replace: true });
                    clearLocalStorage();
                }
                else if ("detail" in response)
                    console.log("Error getting data");
                else
                    setDashboardData(response);
                setLoadState(false);
            })
            .catch(error => console.error(error));
    }, [navigate])

    function menuButtonState(key, value) {
        setMenuButton({ ...menuButton, [key]: value })
    }

    return (
        <div className="HomePage">
            {loadState ? <Loadings /> : ""}
            <div className="HomePage-Container">
                {
                    dashboardData.map((dashboardData, index) => {
                        return (
                            <div key={index} className="HomePage-GroupContainer">
                                <div className="HomePage-GroupName">
                                    {dashboardData.GroupName.toUpperCase().includes("GAME") ? <IoLogoGameControllerB /> :
                                        dashboardData.GroupName.toUpperCase().includes("MEDIA") ? <MdPermMedia /> :
                                            dashboardData.GroupName.toUpperCase().includes("SYS") ? <IoSettingsOutline /> :
                                                dashboardData.GroupName.toUpperCase().includes("NETWORK") ? <FaNetworkWired /> :
                                                    <></>}
                                    {dashboardData.GroupName}
                                </div>
                                <div className="HomePage-SubGroupMainContainer">
                                    {
                                        dashboardData.GroupContent.map((content, index) => {
                                            return (
                                                <div key={index} className="HomePage-SubGroupIndivContainer">
                                                    {
                                                        content.Online ?
                                                            <a className="HomePage-SubGroupTitlePanel" href={content.URL} target="noreferrer">
                                                                {
                                                                    content.Image === "" ? "" :
                                                                        <img className="HomePage-SubGroupImage" alt={content.Name} src={content.Image} />
                                                                }
                                                                <div className="HomePage-SubGroupName">{content.Name}</div>
                                                            </a>
                                                            :
                                                            <div className="HomePage-SubGroupTitlePanelOffline">
                                                                {
                                                                    content.Image === "" ? "" :
                                                                        <img className="HomePage-SubGroupImage" alt={content.Name} src={content.Image} />
                                                                }
                                                                <div className="HomePage-SubGroupName">{content.Name}</div>
                                                            </div>
                                                    }
                                                    {
                                                        content["Extra"] ?
                                                            <div className="HomePage-SubGroupMoreContainer" onClick={() => {
                                                                menuButtonState(content.Name, !menuButton[content.Name]);
                                                            }}>
                                                                <div className={content.Online ? "HomePage-SubGroupMoreIco" : "HomePage-SubGroupMoreIcoOffline"} >
                                                                    <IoMdMore />
                                                                </div>
                                                                {
                                                                    !menuButton[content.Name] ?
                                                                        <div className="HomePage-SubGroupOnHover">
                                                                            more
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    menuButton[content.Name] && content.Online ?
                                                                        <div className="HomePage-SubGroupMoreMenu">
                                                                            {
                                                                                content.Extra.map((extra, index) => {
                                                                                    return (
                                                                                        <div key={index} className="HomePage-SubGroupMoreName" onClick={() => {
                                                                                            if (extra.Type === "copy")
                                                                                                navigator.clipboard.writeText(extra.Content)
                                                                                            else if (extra.Type === "redirect")
                                                                                                window.open(extra.Content, '_blank', 'noreferrer');
                                                                                        }}>{extra.Name}</div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}