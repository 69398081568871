import CryptoJS from "crypto-js";

// Import API URL
import api_url from '../../config.json';

// Check if user is still logged in
export function IsLogin(navigate) {
    if (localStorage.getItem('lh_dashboard_access_token') !== "") {
        fetch(api_url["api_dashboard"] + "/users/verify-user", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('lh_dashboard_access_token')
            }
        })
            .then(response => response.json())
            .then(response => {
                if ("detail" in response) {
                    clearLocalStorage();
                    navigate("/", { replace: true });
                }
            })
            .catch(error => console.error(error));
    }
    else
        navigate("/", { replace: true });
}

// Check session token still exist
export function IsAccountActive(navigate) {
    if (localStorage.getItem('lh_dashboard_access_token') !== null) {
        navigate("/", { replace: true });
    }
}

// Encrypt text
export function encryptText(textVal) {
    let iv = CryptoJS.lib.WordArray.random(16);
    let encryptedData = CryptoJS.AES.encrypt(textVal, CryptoJS.enc.Base64.parse("582fd0bf686c7520fc4d0c7d94298cce"), { iv: iv });
    let joinedData = iv.clone().concat(encryptedData.ciphertext);
    return CryptoJS.enc.Base64.stringify(joinedData);
}

// Clear session
export function clearLocalStorage() {
    localStorage.removeItem('lh_dashboard_access_token');
}