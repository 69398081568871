import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { encryptText } from '../functions/LoginCheck';
import { DefaultTextCustomMSG, PasswordText, PasswordTextCustomMSG } from '../functions/TextModule'

import { IsLogin, clearLocalStorage } from '../functions/LoginCheck';

// Import API URL
import api_url from '../../config.json';

export default function Profile()
{
    const navigate = useNavigate();
    const [dpMessage, setDpMessage] = useState("")
    const [emptyPWD, setEmptyPWD] = useState(false);
    const [emptyNewPWD, setEmptyNewPWD] = useState(false);
    const [emptyNewCfmPWD, setEmptyNewCfmPWD] = useState(false);
    const [pwdMessage, setPwdMessage] = useState("(Cannot be empty)");
    const [newPwdMessage, setNewPwdMessage] = useState("(Cannot be empty)");
    const [showPwd, setShowPwd] = useState(true);
    const [showNewPwd, setShowNewPwd] = useState(true);
    const [showNewCfmPwd, setShowNewCfmPwd] = useState(true);

    useEffect(() => {
        IsLogin(navigate);
        window.scrollTo(0, 0)
    }, [navigate])

    useEffect(()=> {
        document.getElementById("dpnametxt").value = localStorage.getItem("lh_api_display_name")
    })

    return (
        <div className="UserProfile">
            <div className="UserProfile-Container1">
                <div className="UserProfile-Container2">
                    <DefaultTextCustomMSG
                        name="Display Name"
                        inputID="dpnametxt"
                        toggleErrorTxt={true}
                        // task={}
                        message={dpMessage}
                    />
                    <div className="UserProfile-SaveButton"
                        onClick={() => saveDisplayName(setDpMessage, navigate)}>
                        Save
                    </div>
                </div>
                <div className="aLine"/>
                <div className="UserProfile-PWDContainer1">
                    <div className="UserProfile-PWDContainer2">
                        <div className="UserProfile-PWDContainer3">
                            <PasswordTextCustomMSG
                                name="Old Password"
                                inputID="passwordtxt"
                                toggleErrorTxt={emptyPWD || pwdMessage !== "(Cannot be empty)"}
                                // task={handleKeyDown}
                                showElement={showPwd}
                                setShowElement={setShowPwd}
                                message={pwdMessage}
                            />
                        </div>
                        <div className="UserProfile-SaveButton"
                            onClick={() => savePassword(setEmptyPWD, setEmptyNewPWD, setEmptyNewCfmPWD, setNewPwdMessage, setPwdMessage, navigate)}>
                            Save
                        </div>
                    </div>
                        <PasswordTextCustomMSG
                            name="New Password"
                            inputID="newpasswordtxt"
                            toggleErrorTxt={emptyNewPWD || newPwdMessage !== "(Cannot be empty)"}
                            // task={handleKeyDown}
                            showElement={showNewPwd}
                            setShowElement={setShowNewPwd}
                            message={newPwdMessage}
                        />
                        <PasswordText
                            name="Confirm New Password"
                            inputID="cfmnewpasswordtxt"
                            toggleErrorTxt={emptyNewCfmPWD}
                            // task={handleKeyDown}
                            showElement={showNewCfmPwd}
                            setShowElement={setShowNewCfmPwd}
                        />
                </div>
            </div>
        </div>
    );
}

function saveDisplayName(setDpMessage, navigate) {
    let dp = document.getElementById("dpnametxt").value
    localStorage.setItem("lh_api_display_name", dp);
    fetch(api_url["api_dashboard"] + "users/profile/update-displayname", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('lh_dashboard_access_token')
        },
        body: JSON.stringify({
            "displayname": dp
        })
    })
    .then(response => response.json())
    .then(response => {
        if (response["detail"] === "Could not validate credentials") {
            navigate("/", { replace: true });
            clearLocalStorage();
        }
        else if (response["Success"] !== undefined)
            setDpMessage("Saved");
        else
            setDpMessage("Failed to update display name.");
    })
    .catch(error => console.error(error));
}

function savePassword(setEmptyPWD, setEmptyNewPWD, setEmptyNewCfmPWD, setNewPwdMessage, setPwdMessage, navigate) {
    let pwd = document.getElementById("passwordtxt");
    let newPwd = document.getElementById("newpasswordtxt");
    let newCfmPwd = document.getElementById("cfmnewpasswordtxt");
    setPwdMessage("(Cannot be empty)");
    setEmptyPWD(pwd.value === "");
    setEmptyNewPWD(newPwd.value === "");
    setEmptyNewCfmPWD(newCfmPwd.value === "");
    if (newPwd.value !== newCfmPwd.value)
        setNewPwdMessage("(Password do not match)")
    else 
    {
        if (pwd.value !== "" && newPwd.value !== "" && newCfmPwd.value !== "")
        {
            fetch(api_url["api_dashboard"] + "/users/profile/update-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('lh_dashboard_access_token')
                },
                body: JSON.stringify({
                    "password": encryptText(pwd.value),
                    "newpassword": encryptText(newPwd.value)
                })
            })
            .then(response => response.json())
            .then(response => {
                if (response["detail"] === "Could not validate credentials") {
                    navigate("/", { replace: true });
                    clearLocalStorage();
                }
                else if (response["Success"] !== undefined)
                {
                    setPwdMessage("Saved");
                    pwd.value = "";
                    newPwd.value = "";
                    newCfmPwd.value = "";
                }
                else if ("detail" in response) {
                    console.log("Error getting data");
                }
                else
                    setPwdMessage("Invalid Password.");
            })
            .catch(error => console.error(error));
        }
        setNewPwdMessage("(Cannot be empty)")
    }
}