export default function Loading() {
    return (
        <div id="loadingScreen" className="loadingScreenBG">
            <div className="loadingScreenContainerMain">
                <div id="loadingScreen2" className="loadingScreenContainer">
                    <img src='./img/BabyPheonix.png' alt='loading' className="blinkers" />
                </div>
                <div className="loadingScreenText">
                    <span>L</span>
                    <span>O</span>
                    <span>A</span>
                    <span>D</span>
                    <span>I</span>
                    <span>N</span>
                    <span>G</span>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </div>
            </div>
        </div>
    )
}