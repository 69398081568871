import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { encryptText } from '../functions/LoginCheck';
import { DefaultText, DefaultTextCustomMSG, PasswordText, PasswordTextCustomMSG } from '../functions/TextModule'

import { IsLogin, clearLocalStorage } from '../functions/LoginCheck';

// Import API URL
import api_url from '../../config.json';

export default function Login() {
    // Initialise
    const navigate = useNavigate();

    // Textbox state
    const [emptyUID, setEmptyUID] = useState(false);
    const [emptyPWD, setEmptyPWD] = useState(false);
    const [emptyCfmPWD, setEmptyCfmPWD] = useState(false);
    const [showPwd, setShowPwd] = useState(true);
    const [showCfmPwd, setShowCfmPwd] = useState(true);

    // Create user account state
    const [pwdMessage, setPwdMessage] = useState("(Cannot be empty)");
    const [uidMessage, setUidMessage] = useState("(Cannot be empty)");

    // Scroll to the top on load
    useEffect(() => {
        IsLogin(navigate);
        window.scrollTo(0, 0);
    }, [navigate])

    // Enter key function to create user
    const handleKeyDown = (event) => {
        if (event.key === "Enter")
            ValidateCreateUser(setEmptyUID, setEmptyPWD, setEmptyCfmPWD, setPwdMessage, setUidMessage, navigate)
    }

    return (
        <div className="LoginPage">
            <div className='LoginContainer3'>
                <img src='./img/sorinosk1.svg' alt='Logo' className="loginLogo" />
                <div className='vLine' />
            </div>
            <div className="LoginContainer1">
                <DefaultText
                    name="Display Name"
                    inputID="dpnametxt"
                    toggleErrorTxt={false}
                    task={handleKeyDown}
                />
                <DefaultTextCustomMSG
                    name="Username"
                    inputID="usernametxt"
                    toggleErrorTxt={emptyUID}
                    task={handleKeyDown}
                    message={uidMessage}
                />
                <PasswordTextCustomMSG
                    name="Password"
                    inputID="passwordtxt"
                    toggleErrorTxt={emptyPWD}
                    task={handleKeyDown}
                    showElement={showPwd}
                    setShowElement={setShowPwd}
                    message={pwdMessage}
                />
                <PasswordText
                    name="Confirm Password"
                    inputID="cfmpasswordtxt"
                    toggleErrorTxt={emptyCfmPWD}
                    task={handleKeyDown}
                    showElement={showCfmPwd}
                    setShowElement={setShowCfmPwd}
                />
                <div className='LoginContainer2'>
                    <button className="loginSubmit" onClick={() => ValidateCreateUser(setEmptyUID, setEmptyPWD, setEmptyCfmPWD, setPwdMessage, setUidMessage, navigate)}>Create User</button>
                </div>
            </div>
        </div>
    );
}

// Validate create user field
function ValidateCreateUser(setEmptyUID, setEmptyPWD, setEmptyCfmPWD, setPwdMessage, setUidMessage, navigate) {
    // Initialise
    let dp = document.getElementById("dpnametxt").value;
    let uid = document.getElementById("usernametxt").value;
    let pwd = document.getElementById("passwordtxt").value;
    let cfmpwd = document.getElementById("cfmpasswordtxt").value;
    let valid = pwd === cfmpwd;
    setPwdMessage("(Cannot be empty)");
    setUidMessage("(Cannot be empty)");
    setEmptyUID(uid === "");
    setEmptyPWD(pwd === "");
    setEmptyCfmPWD(cfmpwd === "");
    if (!valid) {
        setEmptyPWD(true);
        setPwdMessage("(Confirm password do not match)");
        if (pwd.length < 8)
            setPwdMessage("(Must be at least 8 character)");
    }
    if (uid !== "" && pwd !== "" && valid)
        queryUserFunction(uid, pwd, dp, navigate, setEmptyUID, setUidMessage);
}

// Query to create user
function queryUserFunction(uid, pwd, dp, navigate, setEmptyUID, setUidMessage) {
    fetch(api_url["api_dashboard"] + "/users/sign-up", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "displayname": dp,
            "username": uid,
            "password": encryptText(pwd)
        })
    })
        .then(response => response.json())
        .then(response => {
            if (response["detail"] === "Could not validate credentials") {
                navigate("/", { replace: true });
                clearLocalStorage();
            }
            // If sign up is success, login user. Else if username taken, show error.
            else if (response["Success"] !== undefined) {
                localStorage.setItem('lh_dashboard_access_token', response["access-token"]);
                setUidMessage("(Account Created)");
            }
            else if (response["detail"] !== undefined) {
                if (response["detail"]["Error message"]["errno"] === 1062) {
                    setEmptyUID(true);
                    setUidMessage("(Username taken)");
                }
            }
        })
        .catch(error => console.error(error));
};